<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th v-if="selectable" scope="col">
              <b-checkbox
                v-model="selectAll"
                :indeterminate="selectAllIndeterminate"
                data-testId="select-all"
                @change="onSelectAll"></b-checkbox>
            </th>
            <th scope="col" v-for="(header, index) in headerData" :key="index">{{ header.title || '' }}</th>
            <th scope="col" class="text-center" v-if="hasValidListeners()">{{ translations.actions }}</th>
          </tr>
        </thead>
        <tbody v-if="receivedData.length">
          <tr v-for="(item, trIndex) in receivedData" :key="trIndex">
            <td v-if="selectable">
              <b-checkbox
                :checked="isItemChecked(item.id)"
                @change="(value) => onCheckItemChanged(value, item.id)"></b-checkbox>
            </td>
            <td v-for="(header, tdIndex) in headerData" :key="tdIndex"
              :class="isDate(item[header.property]) ? 'nowrap' : null" >
              <template v-if="item[header.property] != null && typeof item[header.property] === 'object'">
                <component :is="item[header.property].type"
                    v-on="propagateEventsBuilder(item, item[header.property].propagateEvents)"
                    v-bind="item[header.property].propsData">
                  {{item[header.property].text}}
                </component>
              </template>
              <template v-else>
                {{ itemPropertyValue(item, header.property)}}
              </template>
            </td>
            <td
              class="text-center nowrap"
              v-if="hasValidListeners()">
              <b-button v-b-tooltip.hover
              v-for="(listener, btnIndex) of getValidListeners(item)" :key="btnIndex"
              size="sm"
              :title="events[listener].title"
              variant="light"
              class="col-2 btn-action"
              :to="events[listener].redirectLink ? events[listener].redirectLink(item[itemRefKey] || trIndex) : null"
              :target="events[listener].redirectLinkToNewPage ? '_blank' : '_self' "
              @click="!events[listener].redirectLink ? $emit(listener, item[itemRefKey] || trIndex, item) : null">
                <feather :type="events[listener].icon" />
              </b-button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="selectable ? headerLen + 1 : headerLen " class="text-center">{{ noItemsMessage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="havePagination" class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="disabled"
                      @change="changePage"
                      v-model="currentPage"
                      :total-rows="total"
                      :per-page="itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import translations from '@/translations';

export default {

  name: 'FinancialList',
  props: {
    headerData: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: false,
    },
    value: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: false,
    },
    noItemsMessage: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    selectable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    havePagination: {
      type: Boolean,
      default() {
        return true;
      },
    },
    events: {
      type: Object,
      default() {
        return {};
      },
      validator(events) {
        const eventsNames = Object.keys(events);
        for (let i = 0; i < eventsNames.length; i++) {
          const event = events[eventsNames[i]];
          if (!eventsNames[i].startsWith('row-')) {
            return false;
          }
          if (!Object.prototype.hasOwnProperty.call(event, 'title') || !Object.prototype.hasOwnProperty.call(event, 'icon')) {
            return false;
          }
        }
        return true;
      },
    },
    // Reference property key from data for that will be emitted from events,
    // if not given, event will emit the index of the row
    itemRefKey: {
      type: String,
      required: false,
    },
  },
  beforeMount() {
    this.headerLen = this.headerData.length;
    if (this.hasValidListeners()) {
      this.headerLen += 1;
    }

    this.conditionalRequiredValidator(this.havePagination, this.total, 'total');
    this.conditionalRequiredValidator(this.havePagination, this.itemsPerPage, 'itemsPerPage');

    this.updateData(this.data);
  },
  data() {
    return {
      selectAll: false,
      selectAllIndeterminate: false,
      selectedIds: [],
      receivedData: [],
      translations: translations.finance.financial_list,
      headerLen: 0,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    data(newVal) {
      this.updateData(newVal);
    },
    selectAll() {
      this.emitSelectChange();
    },
    selectedIds() {
      this.selectAllIndeterminate = !!this.selectedIds.length;
      this.emitSelectChange();
    },
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.$emit('page-changed', page);
    },
    updateData(newData) {
      if (this.itemsPerPage < newData.length) {
        console.warn('The data array is too big. It will be truncated.');
      }
      this.receivedData = newData.slice(0, this.itemsPerPage);
    },
    hasValidListeners() {
      const listeners = Object.keys(this.$listeners);
      for (let i = 0; i < listeners.length; i++) {
        if (listeners[i] in this.events) {
          return true;
        }
      }
      return false;
    },
    getValidListeners(item) {
      return Object.keys(this.$listeners)
        .filter(key => {
          const event = this.events[key];
          if (!event) {
            return false;
          }
          // if there is a `show_if` function the button is displayed if it returns true when called with the item data
          if (typeof event.show_if === 'function') {
            return event.show_if(item);
          }

          return true;
        });
    },
    onCheckItemChanged(value, id) {
      if (!this.selectAll) {
        if (value) {
          this.selectedIds.push(id);
        } else {
          this.selectedIds = this.selectedIds.filter(v => v !== id);
        }
      } else {
        /* eslint-disable-next-line no-lonely-if */
        if (!value) {
          this.selectedIds.push(id);
        } else {
          this.selectedIds = this.selectedIds.filter(v => v !== id);
        }
      }
    },
    onSelectAll() {
      if (this.selectAllIndeterminate) {
        this.selectAll = false;
      }

      this.selectedIds = [];
    },
    isItemChecked(id) {
      if (this.selectAll) {
        return !this.selectedIds.includes(id);
      }

      return this.selectedIds.includes(id);
    },
    emitSelectChange() {
      this.$emit('select-changed', {
        selectAll: this.selectAll,
        selectedIds: this.selectedIds,
      });
    },
    clearSelection() {
      this.selectAll = false;
      this.selectAllIndeterminate = false;
      this.selectedIds = [];
    },
    itemPropertyValue(item, property) {
      if (property.indexOf('.') > -1) {
        let value = item;
        const parts = property.split('.');
        for (let i = 0; i < parts.length; ++i) {
          const part = parts[i];
          if (value[part] === undefined || value[part] === null) {
            return '-';
          }
          value = value[part];
        }
        return value;
      }

      return item[property] || '-';
    },
    isDate(d) {
      return moment(d, 'YYYY-MM-DD', true).isValid() || moment(d, 'YYYY-MM-DD HH:mm:ss', true).isValid();
    },
    conditionalRequiredValidator(isRequired, prop, propName) {
      if (isRequired && prop === undefined) {
        console.error(`The ${propName} is required but not provided.`);
      }
    },
    propagateEventsBuilder(item, propagateEvents) {
      if (!propagateEvents || !Array.isArray(propagateEvents)) {
        return {};
      }
      const handlers = {};
      propagateEvents.forEach(name => {
        handlers[name] = (...args) => {
          this.$emit(name, item, ...args);
        };
      });
      return handlers;
    },
  },
};

export function associateHeaderDataItem(title, property) {
  return {
    title,
    property,
  };
}

export function generateCustomComponentColumn(componentName, propsData, text) {
  let propagateEvents = [];
  if (propsData.propagateEvents) {
    // eslint-disable-next-line prefer-destructuring
    propagateEvents = propsData.propagateEvents;
    delete propsData.propagateEvents;
  }
  return {
    type: componentName,
    propsData,
    text,
    propagateEvents,
  };
}
</script>

<style>
.btn-action {
  min-width: 50px;
  margin-left: 8px;
}

table>thead>tr>th {
  white-space: nowrap;
}

table>tbody>tr>td.nowrap {
  white-space: nowrap;
}
</style>
